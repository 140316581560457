import debounce from 'lodash.debounce';
import uniqBy from 'lodash.uniqby';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import type { ReactNode } from 'react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { ItemTracker } from 'tracking/ItemTracker';

import type { PaginatedNewsFeedFieldsFragment } from '@news/gql';
import type { NewsEntry, NewsFeedItem } from '@news/lib';
import {
  AD_IN_FEED_KEYWORD,
  EXCLUDE_ADS_FEED_SLUGS,
  IGNORED_AD_FEED_ITEMS,
  getNumberOfLiveArticlesInTopOfFeed,
  isNotDeprecatedNewsFeedItem,
  isNotNullish,
} from '@news/lib';
import { GroupId } from '@news/tracking';

import { ScreenReader, SkipLinkTarget } from 'components/common';
import { Container } from 'components/container';
import { ActionTypes, usePlayNextContentContext } from 'contexts/PlayNextContentContext';
import { TEST_ID } from 'lib/constants';
import { getFeedSlug, getVideosForPNC } from 'lib/helpers';
import { DESKTOP } from 'styles/theme';
import { Article } from 'views/feed/components/article/Article';
import { Hero } from 'views/feed/components/hero';
import { FixedOnlyVideoPlayer } from 'views/feed/components/video-player/FixedOnlyVideoPlayer';

import { FeedContentContainer, LoadMoreButtonContainer, SidebarContainer } from './style';

const AdPlacement = dynamic(() => import('components/ad-placements/AdPlacement').then((module) => module.AdPlacement), {
  ssr: false,
});

const StickyAdPlacement = dynamic(
  () => import('components/ad-placements/StickyAdPlacement').then((module) => module.StickyAdPlacement),
  {
    ssr: false,
  }
);

const ArticleLink = dynamic(() =>
  import('views/feed/components/article/ArticleLink').then((module) => module.ArticleLink)
);

const VideoAsset = dynamic(() =>
  import('views/feed/components/video-asset/VideoAsset').then((module) => module.VideoAsset)
);

const MediaSlider = dynamic(() =>
  import('views/feed/components/media-slider/MediaSlider').then((module) => module.MediaSlider)
);
const Upsell = dynamic(() => import('views/feed/components/upsell/Upsell').then((module) => module.Upsell));
const BreakingNewsWidget = dynamic(() =>
  import('views/feed/components/breaking-news/BreakingNewsWidget').then((module) => module.BreakingNewsWidget)
);
const LiveReport = dynamic(() =>
  import('views/feed/components/live-report/LiveReport').then((module) => module.LiveReport)
);

const SportResults = dynamic(() =>
  import('views/feed/components/sport-results/SportResults').then((module) => module.SportResults)
);

type NewsFeedProps = {
  newsFeed: PaginatedNewsFeedFieldsFragment;
  item?: NewsEntry;
  loadMoreButton: ReactNode;
};

export const NewsFeed = ({ loadMoreButton, newsFeed, item }: NewsFeedProps) => {
  const { setPncList } = usePlayNextContentContext();
  const router = useRouter();
  const isMobile = useIsMobileWidth();

  const feed: NewsFeedItem[] = useMemo(() => {
    const updatedFeed = (newsFeed?.feed?.items ?? [])
      .filter(isNotNullish)
      // Make sure the focused item is not included in the feed
      .filter((feedItem) => !(feedItem.__typename === item?.__typename && feedItem.id === item?.id))
      .filter(isNotDeprecatedNewsFeedItem)
      .filter(excludeModuleOnDesktop(isMobile));
    return uniqBy(updatedFeed, 'id');
  }, [newsFeed?.feed?.items, isMobile, item?.__typename, item?.id]);

  const showAds = !EXCLUDE_ADS_FEED_SLUGS.includes(newsFeed.slug ?? '');
  const sponsors = (newsFeed.sponsors ?? []).filter(isNotNullish);
  const displayHero = newsFeed.slug !== '/';

  /** FOR PNC */
  useEffect(() => {
    const pushNextContentList = getVideosForPNC(feed);
    setPncList({ type: ActionTypes.ADD_ITEMS, items: pushNextContentList });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feed]);

  const getSidebarModule = () => {
    if (isMobile) {
      // On mobile the BreakingNewsModule is shown in the feed and not in the sidebar
      return null;
    }

    return (
      <SidebarContainer>
        {/* TODO: maybe we want breaking-news to be configured from feed? sam as sport-results-module*/}
        {newsFeed?.slug === '/' && <BreakingNewsWidget />}
        {newsFeed.feed?.items.some((item) => item.__typename === 'SportResultsModule') && <SportResults />}
        {showAds && (
          <StickyAdPlacement
            keywords={newsFeed.slug ? [newsFeed.slug] : undefined}
            mobile="mobile1"
            desktop="desktop5"
          />
        )}
      </SidebarContainer>
    );
  };

  const renderFeedItem = useCallback(
    (item: NewsFeedItem, index: number, trackingIndex: number): JSX.Element | undefined => {
      let feedItem: JSX.Element | undefined;

      let shouldTrackClick = false;

      switch (item.__typename) {
        case 'Upsell':
          feedItem = <Upsell key={item.id} item={item} />;
          break;
        case 'BreakingNewsModule':
          feedItem = <BreakingNewsWidget />;
          break;
        case 'VideoAsset':
          shouldTrackClick = true;
          feedItem = <VideoAsset videoAsset={item} />;
          break;
        case 'Article': {
          const prevItem = feed[index - 1];
          const previousIsLive = prevItem?.__typename === 'Article' && !!prevItem.live;
          const currentIsLive = !!item.live;
          shouldTrackClick = true;
          feedItem = (
            <ArticleLink
              article={item}
              useSmallMargin={previousIsLive && currentIsLive}
              feedSlug={newsFeed.slug ?? undefined}
            />
          );
          break;
        }
        case 'LiveReport':
          shouldTrackClick = true;
          feedItem = <LiveReport key={item.id} item={item} />;
          break;
        case 'MediaSlider':
          feedItem = <MediaSlider item={item} index={index} groupId={GroupId.Feed(newsFeed.id)} />;
          break;
        case 'SportResultsModule': {
          feedItem = <SportResults />;
          break;
        }
        default:
          console.log('Unsupported FeedItemType: ', item);
          return undefined;
      }
      return (
        <ItemTracker
          disableClickTracking={!shouldTrackClick}
          item={item}
          positionData={{
            index: trackingIndex,
            groupId: GroupId.Feed(newsFeed.id),
            kilkayaPositionId: item ? 'article' : 'feed',
          }}
        >
          {feedItem}
        </ItemTracker>
      );
    },
    [feed, newsFeed.id, newsFeed.slug]
  );

  const numberOfLiveArticlesInTopOfFeed = useMemo(() => getNumberOfLiveArticlesInTopOfFeed(feed), [feed]);

  //In order for all the just-nu articles to be shown above the first ad,
  //we need to offset the ad placement by the number of "additional" just-nu articles
  const displayAdsOffset = numberOfLiveArticlesInTopOfFeed > 1 ? numberOfLiveArticlesInTopOfFeed - 1 : 0;

  return (
    <Container id="main-content" data-testid={TEST_ID.newsfeed}>
      <SkipLinkTarget />

      {displayHero ? (
        <Hero sponsors={sponsors} title={newsFeed.title} hero={newsFeed.hero} />
      ) : (
        <ScreenReader as={getFeedSlug(router) ? 'h1' : 'p'}>{newsFeed.title}</ScreenReader>
      )}

      <MainFeedContentContainer $isPaddingTop as="section" data-testid={TEST_ID.feedContentContainer}>
        <FocusedItem item={item} newsFeed={newsFeed} />

        {getSidebarModule()}
        {item && <ScreenReader as="h2">Fler artiklar från {newsFeed.title}</ScreenReader>}
        {feed.map((item, index) => {
          // If there is a focused feed item (article page), we need to offset the index by 1
          // If there is a hero, we need to offset the index by an additional 1
          const feedItemRowOffset = (item ? 1 : 0) + (displayHero ? 1 : 0);
          const feedItem: JSX.Element | undefined = renderFeedItem(item, index, index + feedItemRowOffset);
          const displayAd = showAds
            ? getDisplayAd(
                index,
                item.__typename,
                displayAdsOffset,
                feed?.[index - 1]?.__typename,
                newsFeed.slug ?? undefined
              )
            : null;
          return (
            <React.Fragment key={item.id}>
              {displayAd}
              {feedItem}
            </React.Fragment>
          );
        })}
        {loadMoreButton && <LoadMoreButtonContainer>{loadMoreButton}</LoadMoreButtonContainer>}
        <FixedOnlyVideoPlayer />
      </MainFeedContentContainer>
    </Container>
  );
};

const FocusedItem = ({ item, newsFeed }: Pick<NewsFeedProps, 'item' | 'newsFeed'>) => {
  switch (item?.__typename) {
    case 'Article':
      return <Article article={item} feedSlug={newsFeed.slug ?? undefined} />;
    case 'VideoAsset':
      return <VideoAsset videoAsset={item} isMainContent />;
    default:
      return null;
  }
};

const MainFeedContentContainer = styled(FeedContentContainer)`
  padding-bottom: 60px;
`;

/**
 * Do not render ads in the vicinity of these feed items
 */

function getDisplayAd(
  index: number,
  nextItemType: NewsFeedItem['__typename'],
  offset: number,
  previousItemType?: NewsFeedItem['__typename'],
  slug?: string
): JSX.Element | null {
  const offsetIndex = index - offset;

  if (
    IGNORED_AD_FEED_ITEMS.includes(nextItemType) ||
    (previousItemType && IGNORED_AD_FEED_ITEMS.includes(previousItemType))
  ) {
    return null;
  }

  // Show ad after 1 item and then after every 2nd item
  if (offsetIndex <= 0) {
    return null;
  }
  const keywords = slug ? [AD_IN_FEED_KEYWORD, slug] : [AD_IN_FEED_KEYWORD];

  if (offsetIndex === 1) {
    return <AdPlacement mobile="mobile1" desktop="desktop4" keywords={keywords} />;
  }
  if ((offsetIndex - 1) % 2 === 0) {
    return <AdPlacement mobile="mobile2" desktop="desktop4" keywords={keywords} />;
  }
  return null;
}

const excludeModuleOnDesktop = (isMobile: boolean) => (input: NewsFeedItem) => {
  switch (input.__typename) {
    case 'SportResultsModule':
    case 'BreakingNewsModule':
      return isMobile;
    default:
      return true;
  }
};

const useIsMobileWidth = () => {
  const [isMobile, setIsMobile] = useState(true);

  useEffect(() => {
    const checkIsMobile = debounce(() => {
      setIsMobile(window.matchMedia(`(min-width: ${DESKTOP}px)`).matches === false);
    }, 32);

    checkIsMobile();

    addEventListener('resize', checkIsMobile);

    return () => {
      removeEventListener('resize', checkIsMobile);
    };
  }, []);

  return isMobile;
};
